import '../scss/style.scss';
import axios from 'axios';
import bbcode from 'bbcode';

window.setTimeout(function () {
  document.body.classList.add('is-visible');
}, 500);

window.setTimeout(function () {
  loadMessage();
}, 2000);

let $msg = document.querySelector('.message');
let timeout = null;

function loadMessage() {
  $msg.classList.remove('msg-visible');

  axios
    .get('/api.php')
    .then(function (response) {
      handleData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  // resetTimeout();
}

function handleData(data) {
  let $title = document.querySelector('.message__title');
  let $username = document.querySelector('.message__username');
  let $date = document.querySelector('.message__date');
  let $text = document.querySelector('.message__text');

  $title.innerHTML = data.subject;
  $username.innerHTML = data.username;
  $date.innerHTML = data.time;
  bbcode.parse(data.message, function (content) {
    $text.innerHTML = content;
  });

  window.setTimeout(function () {
    $msg.classList.add('msg-visible');

    document.querySelectorAll('img').forEach(function (img) {
      img.onerror = function () {
        this.classList.add('broken');
      };
    });
  }, 300);
}

function resetTimeout() {
  window.clearTimeout(timeout);
  timeout = window.setTimeout(function () {
    loadMessage();
    resetTimeout();
  }, 15000);
}

document.addEventListener('click', loadMessage);

// window.setInterval(loadMessage, 15000);
